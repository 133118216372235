import React from 'react';
import ReactDOM from 'react-dom/client';
import Game from './components/Game';
import players from './utils/players.json';
import './index.css';
import { playerId } from './utils/playerId';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Game wardellNumber={playerId + 1} players={players} targetId={playerId} />
);
