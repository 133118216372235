const playerIds = [
  305, 109, 78450, 56, 1503, 76832, 187, 201943, 76539, 1112, 731, 904, 76970,
  270,
];
const startDate = new Date('08/26/2023');
const currentDate = new Date();

const playerIndex = Math.floor(
  Math.abs(currentDate - startDate) / (1000 * 60 * 60 * 24)
);

export const playerId = playerIds[Math.min(playerIndex, playerIds.length - 1)];
