import React from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

class Navbar extends React.Component {
  handleQuestionClick() {
    this.props.onQuestionClick();
  }

  handleInfoClick() {
    this.props.onInfoClick();
  }

  render() {
    return (
      <header className="flex flex-row items-center justify-between flex-nowrap border-b navbar">
        <div className="flex flex-row items-center w-16 py-3 px-4">
          <button
            className="py-0.5 px-1"
            onClick={() => this.handleInfoClick()}
          >
            <InfoOutlinedIcon />
          </button>
        </div>
        <div className="font-sans text-4xl font-extrabold text-center">
          {' '}
          Wardell{' '}
        </div>
        <div className="flex flex-row items-center justify-end w-16 px-4">
          <button
            className="py-0.5 px-1"
            onClick={() => this.handleQuestionClick()}
          >
            <HelpOutlineOutlinedIcon />
          </button>
        </div>
      </header>
    );
  }
}

export default Navbar;
