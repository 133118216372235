import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

export default class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
      inputValue: '',
    };
  }

  handleChange(event, newValue) {
    if (typeof newValue === 'string' || newValue instanceof String) {
      return;
    }

    this.props.onGuess(newValue);
    this.setState({ value: null, inputValue: '' });
  }

  handleInputChange(event, newInputValue) {
    this.setState({ inputValue: newInputValue });
  }

  render() {
    const playerOptions = [];

    Object.values(this.props.players).forEach((playerObject) => {
      playerOptions.push({
        label: playerObject.DISPLAY_FIRST_LAST,
        playerId: playerObject.PERSON_ID,
      });
    });

    // TODO: consider using https://github.com/kentcdodds/match-sorter with WORD STARTS WITH mode
    const filterOptions = createFilterOptions({
      limit: 5,
    });

    let placeholder;
    if (
      this.props.guesses.length > 0 &&
      this.props.guesses[this.props.guesses.length - 1].playerObject
        .PERSON_ID === this.props.targetId
    ) {
      placeholder = 'You solved it in ' + this.props.guesses.length + '!';
    } else if (this.props.guesses.length >= 8) {
      placeholder = 'Game over';
    } else {
      placeholder = 'Guess ' + (this.props.guesses.length + 1) + ' of 8';
    }

    return (
      <div className='flex justify-center searchbar'>
        <Autocomplete
          open={this.state.inputValue.length > 0}
          value={this.state.value}
          onChange={(event, newValue) => this.handleChange(event, newValue)}
          inputValue={this.state.inputValue}
          onInputChange={(event, newInputValue) =>
            this.handleInputChange(event, newInputValue)
          }
          disabled={
            this.props.guesses.length >= 8 ||
            (this.props.guesses.length > 0 &&
              this.props.guesses[this.props.guesses.length - 1].playerObject
                .PERSON_ID === this.props.targetId)
          }
          filterOptions={filterOptions}
          freeSolo
          disableClearable
          blurOnSelect
          isOptionEqualToValue={(option, value) =>
            option.playerId === value.playerId
          }
          id='player-search'
          options={playerOptions}
          sx={{ width: 500 }}
          renderInput={(params) => (
            <TextField {...params} label={placeholder} />
          )}
        />
      </div>
    );
  }
}
