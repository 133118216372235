import React from 'react';
import Navbar from './Navbar';
import SearchBar from './SearchBar';
import PlayerTable from './PlayerTable';

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import {
  APP_NAME,
  APP_URL,
  TWITTER_NAME,
  TWITTER_URL,
  REDDIT_NAME,
  REDDIT_URL,
} from '../utils/constants';

class Game extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      guesses: [],
      questionOpen: true,
      infoOpen: false,
      resultOpen: false,
    };
  }

  handleQuestionClick = () => {
    this.setState({ questionOpen: true });
  };

  handleInfoClick = () => {
    this.setState({ infoOpen: true });
  };

  handleQuestionClose = () => {
    this.setState({ questionOpen: false });
  };

  handleInfoClose = () => {
    this.setState({ infoOpen: false });
  };

  handleResultClose = () => {
    this.setState({ resultOpen: false });
  };

  handleShareClick = () => {
    const guesses = this.state.guesses;
    let numGuesses = isWinner(guesses, this.props.targetId)
      ? guesses.length
      : 'X';
    let gameNumber = this.props.wardellNumber;
    let gameText = `${APP_NAME} ${gameNumber} ${numGuesses}/8\n`;

    const colorMap = {
      '': '⬜',
      ' yellow': '🟨',
      ' green': '🟩',
    };

    guesses.forEach((guessObject) => {
      gameText += '\n';
      gameText += colorMap[guessObject.nameColor];
      gameText += colorMap[guessObject.teamColor];
      gameText += colorMap[guessObject.fromColor];
      gameText += colorMap[guessObject.toColor];
      gameText += colorMap[guessObject.posColor];
      gameText += colorMap[guessObject.htColor];
    });

    gameText += `\n${APP_URL}`;

    navigator.clipboard.writeText(gameText).then(
      function () {
        /* clipboard successfully set */
        return;
      },
      function () {
        /* clipboard write failed */
        return;
      }
    );
  };

  handleGuess(guess) {
    const guessedPlayerObject = this.props.players[guess.playerId];
    const targetPlayerObject = this.props.players[this.props.targetId];

    const upArrowDiv = <div className="arrow">↑</div>;
    const downArrowDiv = <div className="arrow">↓</div>;

    const nameColor =
      guess.playerId === targetPlayerObject.PERSON_ID ? ' green' : '';

    let teamColor = '';
    const guessedTeamsSet = new Set();
    guessedPlayerObject.SEASONS.forEach((season) =>
      guessedTeamsSet.add(season.TEAM_ABBREVIATION)
    );
    const teams = Array.from(guessedTeamsSet).join(', ');

    const targetTeamsSet = new Set();
    targetPlayerObject.SEASONS.forEach((season) =>
      targetTeamsSet.add(season.TEAM_ABBREVIATION)
    );

    if (
      guessedTeamsSet.size === targetTeamsSet.size &&
      Array.from(guessedTeamsSet).every((team) => targetTeamsSet.has(team))
    ) {
      teamColor = ' green';
    } else {
      for (const team of guessedTeamsSet) {
        if (targetTeamsSet.has(team)) {
          teamColor = ' yellow';
          break;
        }
      }
    }

    let fromColor = '';
    let fromArrow = <></>;

    let from = guessedPlayerObject.FROM_YEAR;
    const guessedFromInt = parseInt(from, 10);
    const targetFromInt = parseInt(targetPlayerObject.FROM_YEAR, 10);

    if (guessedFromInt === targetFromInt) {
      fromColor = ' green';
    } else {
      if (Math.abs(guessedFromInt - targetFromInt) <= 5) {
        fromColor = ' yellow';
      }

      if (guessedFromInt < targetFromInt) {
        fromArrow = upArrowDiv;
      } else {
        fromArrow = downArrowDiv;
      }
    }

    let to = guessedPlayerObject.TO_YEAR;
    let toColor = '';
    let toArrow = <></>;

    const guessedToInt = parseInt(to, 10);
    const targetToInt = parseInt(targetPlayerObject.TO_YEAR, 10);

    if (guessedToInt === targetToInt) {
      toColor = ' green';
    } else {
      if (Math.abs(guessedToInt - targetToInt) <= 5) {
        toColor = ' yellow';
      }

      if (guessedToInt < targetToInt) {
        toArrow = upArrowDiv;
      } else {
        toArrow = downArrowDiv;
      }
    }

    let posColor = '';
    let guessedPositions = guessedPlayerObject.POSITION.split('-').map(
      (pos) => pos[0]
    );
    let position = guessedPositions.join('-');

    if (guessedPlayerObject.POSITION === targetPlayerObject.POSITION) {
      posColor = ' green';
    } else {
      let targetPositions = targetPlayerObject.POSITION.split('-').map(
        (pos) => pos[0]
      );
      for (const pos of guessedPositions) {
        if (targetPositions.includes(pos)) {
          posColor = ' yellow';
          break;
        }
      }
    }

    let htColor = '';
    let htArrow = <></>;

    let height = guessedPlayerObject.HEIGHT;
    const guessedInches = heightToInches(height);
    const targetInches = heightToInches(targetPlayerObject.HEIGHT);

    if (height === targetPlayerObject.HEIGHT) {
      htColor = ' green';
    } else {
      if (Math.abs(guessedInches - targetInches) <= 2) {
        htColor = ' yellow';
      }

      if (guessedInches < targetInches) {
        htArrow = upArrowDiv;
      } else {
        htArrow = downArrowDiv;
      }
    }

    let guessObject = {
      playerObject: guessedPlayerObject,
      nameColor: nameColor,
      teams: teams,
      teamColor: teamColor,
      fromColor: fromColor,
      fromArrow: fromArrow,
      toColor: toColor,
      toArrow: toArrow,
      position: position,
      posColor: posColor,
      htColor: htColor,
      htArrow: htArrow,
    };

    const guessCount = this.state.guesses.length;
    this.setState({ guesses: this.state.guesses.concat(guessObject) });
    if (guess.playerId === this.props.targetId || guessCount >= 7) {
      this.setState({ resultOpen: true });
    }
  }

  render() {
    return (
      <div>
        <Dialog open={this.state.infoOpen} maxWidth="lg">
          <DialogTitle
            sx={{
              textAlign: 'center',
              textTransform: 'uppercase',
              fontWeight: 'bold',
            }}
          >
            About
            <IconButton
              className="close-button"
              aria-label="close"
              onClick={() => this.handleInfoClose()}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Box m={2}>
                Inspired by{' '}
                <a
                  href="https://www.nytimes.com/games/wordle/index.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  <strong>Wordle</strong>
                </a>{' '}
                and{' '}
                <a
                  href="https://poeltl.dunk.town/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <strong>Poeltl</strong>
                </a>
              </Box>
              <Box m={2}>Created by Ahmed Kaddoura</Box>
              <Box m={2}>
                Contact me on{' '}
                <a href={REDDIT_URL} target="_blank" rel="noreferrer">
                  <strong>Reddit {REDDIT_NAME}</strong>
                </a>{' '}
                or{' '}
                <a href={TWITTER_URL} target="_blank" rel="noreferrer">
                  <strong>X {TWITTER_NAME}</strong>
                </a>
              </Box>
            </DialogContentText>
          </DialogContent>
        </Dialog>
        <Dialog open={this.state.questionOpen} maxWidth="lg">
          <DialogTitle
            sx={{
              textAlign: 'center',
              textTransform: 'uppercase',
              fontWeight: 'bold',
            }}
          >
            How to play
            <IconButton
              className="close-button"
              aria-label="close"
              onClick={this.handleQuestionClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box m={2}>
              Guess the historic mystery player in eight tries. Each guess must
              be a retired NBA player.
            </Box>
            <Box m={2}>
              <span className="green-line">Green in any column</span> indicates
              an exact match.
            </Box>
            <Box m={2}>
              <span className="green-line">
                Green in the <strong>TEAM</strong> column
              </span>{' '}
              indicates the mystery player played for this{' '}
              <strong>exact set</strong> of teams, no more, no less.
            </Box>
            <Box m={2}>
              <span className="yellow-line">
                Yellow in the <strong>TEAM</strong> column
              </span>{' '}
              indicates the mystery player played for{' '}
              <strong>at least one</strong> of these teams.
            </Box>
            <Box m={2}>
              Team names reflect franchise name changes. For example, SEA
              becomes OKC and NJN becomes BKN.
            </Box>
            <Box m={2}>
              <span className="yellow-line">
                Yellow in the <strong>POS</strong> column
              </span>{' '}
              indicates a partial match to the mystery player's position.
            </Box>
            <Box m={2}>
              <span className="yellow-line">
                Yellow in the <strong>TO</strong> and <strong>FROM</strong>{' '}
                columns
              </span>{' '}
              indicate the mystery player is within <strong>5 years</strong> of
              the mystery player.
            </Box>
            <Box m={2}>
              <span className="yellow-line">
                Yellow in the <strong>HT</strong> column
              </span>{' '}
              indicates the mystery player is within <strong>2 inches</strong>{' '}
              of the mystery player.
            </Box>
            <Box m={2}>A new mystery player will be available each day!</Box>
          </DialogContent>
        </Dialog>
        <Dialog open={this.state.resultOpen} maxWidth="lg">
          <DialogTitle
            sx={{
              textAlign: 'center',
              textTransform: 'uppercase',
              fontWeight: 'bold',
            }}
          >
            {this.state.guesses.length > 0 &&
            this.state.guesses[this.state.guesses.length - 1].playerObject
              .PERSON_ID === this.props.targetId
              ? 'You win!'
              : 'You lose!'}
            <IconButton
              className="close-button"
              aria-label="close"
              onClick={() => this.handleResultClose()}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <div className="flex flex-col items-center">
              <Box sx={{ textAlign: 'center', typography: 'h4' }} m={2}>
                {this.props.players[this.props.targetId].DISPLAY_FIRST_LAST}
              </Box>
              {isWinner(this.state.guesses, this.props.targetId) && (
                <Box sx={{ textAlign: 'center', typography: 'h5' }} m={2}>
                  You solved it in {this.state.guesses.length}{' '}
                  {this.state.guesses.length === 1 ? 'guess' : 'guesses'}
                </Box>
              )}
              <Box sx={{ textAlign: 'center', typography: 'body1' }} m={2}>
                Play again tomorrow for a new mystery player!
              </Box>
              <Button
                variant="contained"
                size="large"
                onClick={this.handleShareClick}
                style={{ backgroundColor: '#79a86b', fontWeight: 'bold' }}
              >
                Share
              </Button>
            </div>
          </DialogContent>
        </Dialog>
        <Navbar
          onQuestionClick={this.handleQuestionClick}
          onInfoClick={this.handleInfoClick}
        />
        <SearchBar
          players={this.props.players}
          targetId={this.props.targetId}
          guesses={this.state.guesses}
          onGuess={(guess) => this.handleGuess(guess)}
        />
        {this.state.guesses.length > 0 && (
          <PlayerTable
            targetPlayer={this.props.players[this.props.targetId]}
            guesses={this.state.guesses}
          />
        )}
      </div>
    );
  }
}

export default Game;

function isWinner(guesses, targetId) {
  return (
    guesses.length > 0 &&
    guesses[guesses.length - 1].playerObject.PERSON_ID === targetId
  );
}

function heightToInches(height) {
  const parts = height.split('-');
  const feet = parseInt(parts[0], 10);
  const inches = parseInt(parts[1], 10);
  const total = feet * 12 + inches;
  return total;
}
