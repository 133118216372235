import React from 'react';

export default class PlayerTable extends React.Component {
  scrollToBottom() {
    this.playerTableBody.scrollTop = this.playerTableBody.scrollHeight;
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  render() {
    const rows = [];
    this.props.guesses.forEach((guess, index) => {
      rows.push(
        <PlayerRow
          key={index}
          guessedPlayer={guess}
          targetPlayer={this.props.targetPlayer}
          failed={false}
        />
      );
    });

    /* User has made 8 incorrect guesses, add row with correct player */
    if (
      this.props.guesses.length === 8 &&
      this.props.guesses[7].playerObject.PERSON_ID !==
        this.props.targetPlayer.PERSON_ID
    ) {
      rows.push(
        <PlayerRow
          key={8}
          guessedPlayer={this.props.targetPlayer}
          targetPlayer={this.props.targetPlayer}
          failed={true}
        />
      );
    }

    return (
      <div className='table-box'>
        <div className='player-table'>
          <div className='player-table-head'>
            <div className='player-table-row'>
              <div className='player-table-cell'></div>
              <div className='player-table-cell'>Team</div>
              <div className='player-table-cell'>From</div>
              <div className='player-table-cell'>To</div>
              <div className='player-table-cell'>Pos</div>
              <div className='player-table-cell'>Ht</div>
            </div>
          </div>
          <div
            className='player-table-body'
            ref={(el) => {
              this.playerTableBody = el;
            }}
          >
            {rows}
          </div>
        </div>
      </div>
    );
  }
}

class PlayerRow extends React.Component {
  render() {
    const cellClass = 'player-table-cell';
    const failed = this.props.failed;
    let guessObject = this.props.guessedPlayer;

    if (failed) {
      guessObject = {
        playerObject: this.props.targetPlayer,
        nameColor: '',
        teams: createTeamsString(this.props.targetPlayer.SEASONS),
        teamColor: '',
        fromColor: '',
        fromArrow: '',
        toColor: '',
        toArrow: '',
        position: createPositionString(this.props.targetPlayer.POSITION),
        posColor: '',
        htColor: '',
        htArrow: '',
      };
    } else {
      guessObject = this.props.guessedPlayer;
    }

    const guessedPlayer = guessObject.playerObject;
    const name = guessedPlayer.DISPLAY_FIRST_LAST;
    const from = guessedPlayer.FROM_YEAR;
    const to = guessedPlayer.TO_YEAR;
    const height = guessedPlayer.HEIGHT;

    return (
      <div className={'player-table-row' + (this.props.failed ? ' lost' : '')}>
        <div className={cellClass + guessObject.nameColor}>
          <div>
            <div className='text'>{name}</div>
          </div>
        </div>
        <div className={cellClass + guessObject.teamColor}>
          <div>
            <div className='text'>{guessObject.teams}</div>
          </div>
        </div>
        <div className={cellClass + guessObject.fromColor}>
          <div>
            <div className='text'>{from}</div>
            {guessObject.fromArrow}
          </div>
        </div>
        <div className={cellClass + guessObject.toColor}>
          <div>
            <div className='text'>{to}</div>
            {guessObject.toArrow}
          </div>
        </div>
        <div className={cellClass + guessObject.posColor}>
          <div>
            <div className='text'>{guessObject.position}</div>
          </div>
        </div>
        <div className={cellClass + guessObject.htColor}>
          <div>
            <div className='text'>{height}</div>
            {guessObject.htArrow}
          </div>
        </div>
      </div>
    );
  }
}

function createTeamsString(seasonsArr) {
  const guessedTeamsSet = new Set();
  seasonsArr.forEach((season) => guessedTeamsSet.add(season.TEAM_ABBREVIATION));
  return Array.from(guessedTeamsSet).join(', ');
}

function createPositionString(position) {
  let guessedPositions = position.split('-').map((pos) => pos[0]);
  return guessedPositions.join('-');
}
